import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import CarCard from "./CarCard";
import { ShimmerCarCardCargando } from "../widgets/miscelaneos";

import carPlaceholder from "../../images/car-placeholder.png";

export default function CatalogoLayoutSeminuevos(props){

    const { post, isLoading, parameters, empresa } = props;
console.log(props,"ahsaj")
    if(isLoading) {
        return(
            <ShimmerCarCardCargando auto="Seminuevos"/>
        )
    }

    if (!post || post.length === 0) {
        return (
        <div
            style={{
            textAlign: "center",
            minHeight: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
            }}
        >
            <h5>No se encontraron autos.</h5>
        </div>
        );
    }

    const pintaCarCards = post?.map((obj, i) => {
        return(
            <Col key={i} px={2} xs={12} className="mb-3 px-2 col-6">
                <CarCard
                    image={obj.picture}
                    name={obj.nombre}
                    title={obj.nombre}
                    year={obj.year}
                    brand={obj.marca}
                    price={obj.precio}
                    model={obj.nombre}
                    trans={obj.trans}
                    url={``}
                    linksTo={
                        empresa=='nissan'?
                        parameters
                          ? `/seminuevos-nissan/${obj.slug}` + parameters
                          : `/seminuevos-nissan/${obj.slug}`
                          :
                          empresa=='chevrolet'?
                          parameters
                            ? `/seminuevos-chevrolet/${obj.slug}` + parameters
                            : `/seminuevos-chevrolet/${obj.slug}`
                            :
                            parameters
                              ? `/seminuevos/${obj.slug}` + parameters
                              : `/seminuevos/${obj.slug}`
                    }
                />
            </Col>
        )
    })

    return(
        <>
            <Row xl={4} lg={4} md={3} sm={1} xs={1} >
                {pintaCarCards}
            </Row>
        </>
    )

}